import React from "react";
// import { TiThMenu } from "react-icons/ti";
import { AiOutlineClose } from "react-icons/ai";

const Sidebar = ({ isOpen, toggleSidebar, Data }) => {
  const className = {
    sidebarContainer: `fixed inset-0 bg-black bg-opacity-75 z-20 transform ${
      isOpen ? "translate-x-0" : "-translate-x-full"
    } transition-transform duration-300 ease-in-out lg:hidden`,
    sidebarContent: "w-64 h-full bg-black p-4 flex flex-col justify-between",
    closeButton: "text-white mb-4 self-end",
    menuItem: "text-white no-underline font-poppins text-2xl font-medium flex items-center gap-2",
    iconContainer: "flex gap-2 justify-center mt-4",
    icon: "w-fit h-fit block bg-iconic-pink text-white rounded-full p-3",
  };

  return (
    <div className={className.sidebarContainer}>
      <div className={className.sidebarContent}>
        <button onClick={toggleSidebar} className={className.closeButton}>
          <AiOutlineClose size={24} />
        </button>
        <div>
          <ul className="space-y-4">
            {Data.menu1.map((data, index) => (
              <li key={index}>
                <a href={data.link} className={className.menuItem}>
                  {data.title}
                </a>
              </li>
            ))}
            {Data.menu2.map((data, index) => (
              <li key={index}>
                <a href={data.link} className={className.menuItem}>
                  {data.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className={className.iconContainer}>
          {Object.values(Data.icons).map((item, index) => (
            <a key={index} className={className.icon} href={item.link}>
              {item.icon}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import Carousel from "../Components/Carousel";
import Database from "../DB/Database";

const Caroucase = () => {

    const className = {
      container: "w-screen grid place-items-center ",
      banner:
        "w-full grid place-items-center bg-[url('./attachments/background.png')] py-8 bg-center bg-no-repeat bg-cover",
      innerBox: "py-10 space-y-5 grid place-items-center",
      title: "text-3xl font-Playfair font-bold text-center",
      borderBox: "space-y-2 grid place-items-center",
      border: "border border-iconic-pink",
      grids: "w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 place-items-center",
      gridbox: "grid place-items-center w-full space-y-4",
      header: "font-Poppins italic",
      arrMen: "w-full h-auto grid place-items-center grid-cols-1 md:grid-cols-2 xl:grid-cols-4",
      arrBox: "w-full grid place-items-center py-14 text-white",
      arrInnerBox: "flex items-center justify-center gap-5",
      arrTitle: "text-xl font-Poppins font-medium",
      arrImg: "h-12 w-auto",
    };

    const Data = Database.Carocase;

    return (
      <div className={className.container}>
        <div className={className.banner}>
          <div className={className.innerBox}>
            <p className={className.header}>
              {Data.header}
            </p>
            <h1 className={className.title}>{Data.title}</h1>

            <div className={className.borderBox}>
              <hr className={`w-60 ${className.border}`} />
              <hr className={`w-40 ${className.border}`} />
            </div>

            <Carousel slides={Data.carousel}/>
          </div>
        </div>

        <div className={className.arrMen}>
          {Data.benefits.map((data, index) => {
            return (
              <div className={`${className.arrBox} ${index % 2 === 0 ? "bg-[#282525]" : "bg-black/70"}`}>
                <div className={className.arrInnerBox}>
                  <img src={data.icon} alt="" className={className.arrImg}/>
                  <h1 className={className.arrTitle}>
                    {data.title}
                  </h1>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
    

}

export default Caroucase
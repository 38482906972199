import Home from "./Pages/Home";
import './App.css';
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import ShopStores from "./Pages/ShopStores";
import { Route, Routes } from "react-router";
import Homepage from "./main/HomePage";

function App() {
  return (
    <div className="h-full overflow-x-hidden">
      <Navbar/>
      <Routes>
        <Route path="" element={<Homepage />} />
        <Route path="Shop" element={<ShopStores />}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;

import { useEffect, useState } from "react";
import Database from "../DB/Database";
import RatingReview from "../Components/RatingStars";

const Showcase = () => {
    const Data = Database.Showcase;
    const [filteredCatalogue, setFilteredCatalogue] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState("");

    const className = {
      container:
        "w-screen p-10 grid grid-cols-1 xl:grid-cols-2 gap-10 place-items-center h-full ",
      box: "w-full h-full space-y-10 grid place-items-center py-10 border-b-2 border-b-iconic-pink xl:border-none",
      menus:
        "flex items-center justify-between overflow-x-auto xl:overflow-x-hidden gap-20 w-full md:px-12 xl:w-fit xl:px-10 py-5",
      menu: "font-Poppins text-xl border-b-iconic-pink cursor-pointer",
      showcaseBox:
        "w-auto h-auto flex flex-col items-center justify-center gap-5 px-10 md:px-20",
      categoryBox:
        "w-auto h-auto flex flex-col items-center justify-center gap-5 px-8",
      catTitle: "block w-fit h-fit hover:underline font-Playfair capitalize tracking-wide text-xl text-center",
      catPrice: "text-iconic-pink font-Poppins text-lg",
      grid: "w-full h-full grid grid-cols-1 md:grid-cols-2 gap-10 pt-20 place-items-center",
      dropdown:
        "px-4 py-2 border border-gray-300 rounded-lg text-black font-Poppins text-lg w-full max-w-xs",
    };

    useEffect(() => {
      setFilteredCatalogue(
        Data.highlight.filter((product) => product.tag === Data.menu[0].tag)
      );
      setSelectedMenu(Data.menu[0].tag);
    }, []);

    const handleMenuChange = (e) => {
      const selectedTag = e.target.value;
      setSelectedMenu(selectedTag);

      if (selectedTag === "") {
        setFilteredCatalogue(
          Data.highlight.filter((product) => product.tag === Data.menu[0].tag)
        );
      } else {
        setFilteredCatalogue(
          Data.highlight.filter((product) => product.tag === selectedTag)
        );
      }
    };

    const handleMenuClick = (goto) => {
      if (selectedMenu === goto) {
        setFilteredCatalogue(
          Data.highlight.filter((product) => product.tag === Data.menu[0].tag)
        );
        setSelectedMenu("");
      } else {
        const filteredProducts = Data.highlight.filter(
          (product) => product.tag === goto
        );
        setFilteredCatalogue(filteredProducts);
        setSelectedMenu(goto);
      }
    };

    return (
      <div className={className.container}>
        <div className={className.box}>
          {/* Dropdown for mobile */}
          <select
            value={selectedMenu}
            onChange={handleMenuChange}
            className={`${className.dropdown} block xl:hidden`}
          >
            {Data.menu.map((data, index) => (
              <option key={index} value={data.tag}>
                {data.title}
              </option>
            ))}
          </select>

          {/* Standard menu for tablets and desktops */}
          <ul className={`${className.menus} hidden xl:flex`}>
            {Data.menu.map((data, index) => (
              <li
                key={index}
                className={`${className.menu} ${
                  data.tag === selectedMenu
                    ? "border-b-2 font-bold text-black"
                    : "border-none text-gray-400"
                }`}
                onClick={() => handleMenuClick(data.tag)}
              >
                {data.title}
              </li>
            ))}
          </ul>

          <div className={className.showcase}>
            {filteredCatalogue.map((data, index) => (
              <div key={index} className={className.showcaseBox}>
                <img src={data.img} alt="picture" />

                <RatingReview rating={data.rates} setRating={() => null} />

                <a className={className.catTitle} href={data.link}>{data.title}</a>

                <p className={className.catPrice}>Rp. {data.price}</p>
              </div>
            ))}
          </div>
        </div>

        <div className={className.grid}>
          {Data.grids.map((data, index) => {
            return (
              <div key={index} className={className.categoryBox}>
                <img src={data.photo} alt="picture" />

                <RatingReview rating={data.rates} setRating={() => null} />

                <a className={className.catTitle} href={data.link}>{data.title}</a>

                <p className={className.catPrice}>Rp. {data.price}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
}

export default Showcase;
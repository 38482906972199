import { useState } from 'react';
import Database from '../DB/Database.js';
import RatingReview from '../Components/RatingStars.js';

const ShopCatalogue = () => {
    const Data = Database.ShopCat;
    const [filteredCatalogue, setFilteredCatalogue] = useState(
      Data.catalogue1
    );
    const [selectedMenu, setSelectedMenu] = useState(null);


    const className = {
      container:
        "w-full h-fit py-5 flex flex-col md:my-0 items-center-center gap-10",
      Menucategories:
        " flex flex-col items-center gap-6 w-screen xl:w-full px-5 pt-10 md:py-10 bg-iconic-black text-white ",
      titleBox: "text-center font-Poppins space-y-4",
      header: "text-sm",
      title: "text-3xl md:text-4xl font-medium ",
      borderBox: "space-y-2 grid place-items-center",
      border: "border border-iconic-pink",
      menus:
        "flex items-center justify-between overflow-x-auto xl:overflow-x-hidden gap-20 w-full xl:w-fit xl:px-10 py-5",
      menu: "text-center space-y-6 text-white group ",
      innerMenu: `cursor-pointer w-[150px] px-10 py-10 h-fit  grid place-items-center group-hover:bg-iconic-pink rounded-full border border-white`,
      menuPara: `font-Playfair tracking-wide group-hover:text-iconic-pink `,
      gridCat:
        "w-full h-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 place-items-center gap-5 p-10 list-none",
      categoryBox:
        "w-auto h-auto flex flex-col items-center justify-center gap-5 px-8",
      catTitle: "block w-fit hover:underline font-Playfair capitalize tracking-wide text-lg text-center",
      catPrice: "text-iconic-pink font-Poppins text-sm",
    };


    
  
     const handleMenuClick = (goto) => {
       if (selectedMenu === goto) {
         setFilteredCatalogue(Data.catalogue1);
         setSelectedMenu(null);
       } else {
         const filteredProducts = Data.catalogue1.filter(
           (product) => 
            product.category === goto
         );
         setFilteredCatalogue(filteredProducts);
         setSelectedMenu(goto);
       }
     };

     console.log(filteredCatalogue);

    return (
      <div className={className.container}>
        <div className={className.Menucategories}>
          <div className={className.titleBox}>
            <h4 className={className.header}>{Data.header}</h4>
            <h1 className={className.title}>{Data.title}</h1>
          </div>

          <div className={className.borderBox}>
            <hr className={`w-60 ${className.border}`} />
            <hr className={`w-40 ${className.border}`} />
          </div>

          <ul className={className.menus}>
            {Data.menus.map((data, index) => {
                return (
                  <li
                    key={index}
                    className={className.menu}
                    onClick={() => handleMenuClick(data.goto)}
                  >
                    <div
                      className={`${className.innerMenu} ${
                        data.goto === selectedMenu ? "bg-iconic-pink" : ""
                      }`}
                    >
                      <img src={data.icon} style={{ stroke: "white" }} />
                    </div>
                    <p
                      className={`${className.menuPara} ${
                        data.goto === selectedMenu ? "text-iconic-pink" : ""
                      }`}
                    >
                      {data.title}
                    </p>
                  </li>
                );
            })}
          </ul>
        </div>

        <ul className={className.gridCat}>
            {filteredCatalogue.map((data, index) => {
              if(index < 8){
                 return <li key={index} className={className.categoryBox}>
                   <img src={data.photo} alt="picture" />

                   <RatingReview rating={data.rates} setRating={() => null}/>

                   <a className={className.catTitle} href={data.link}>{data.title}</a>

                   <p className={className.catPrice}>Rp. {data.price}</p>
                 </li>
              }else{
                return null;
              }
            })}
        </ul>

        
      </div>


    );
}


export default ShopCatalogue;
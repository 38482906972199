import Database from "../DB/Database";


const Banner = ( ) => {
    const Data = Database.banner;

    const className = {
      container: `w-screen grid place-items-center bg-[url('./attachments/banner-midder.jpg')] py-20 bg-center bg-cover bg-no-repeat`,
      innerBox: " sm:p-10 space-y-5 grid place-items-center",
      title: "text-7xl font-Poppins font-bold text-center",
      borderBox: "space-y-2 grid place-items-center",
      border: "border border-iconic-pink",
      paragraf: "font-Roboto text-sm font-light text-center text-gray-800",
      link: "px-5 py-3 bg-iconic-pink rounded-md font-medium text-white block w-fit",
    };


    return (
      <div className={className.container}>
        <div className={className.innerBox}>
          <h1 className={className.title}>{Data.title}</h1>

          <div className={className.borderBox}>
            <hr className={`w-60 ${className.border}`} />
            <hr className={`w-40 ${className.border}`} />
          </div>

          <pre className={className.paragraf}>{Data.subtitle}</pre>

          <a className={className.link} href={Data.button.link}>
            {Data.button.title}
          </a>
        </div>
      </div>
    );
}


export default Banner;
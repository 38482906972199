import React, { useEffect, useState } from "react";
import banner1 from "../attachments/Banner/banner1.png";
import banner2 from "../attachments/Banner/banner2.png";
import banner3 from "../attachments/Banner/banner3.png";
import { Link } from "react-router-dom";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const banners = [
    {
      image: banner1,
      button: {
        title: "Get it inside the Cart!",
        link: "/Shop",
        className:
          "bg-transparent border-white hover:border-blue-400 hover:bg-blue-400",
      },
    },
    {
      image: banner2,
      button: {
        title: "Into the Checkout!",
        link: "/Shop",
        className:
          "bg-transparent border-white hover:border-yellow-300 hover:bg-yellow-300",
      },
    },
    {
      image: banner3,
      button: {
        title: "Buy 1 Get 1 now!",
        link: "/Shop",
        className:
          "bg-transparent border-white hover:border-red-500 hover:bg-red-500",
      },
    },
  ];

  const changeSlide = (direction) => {
    const totalItems = banners.length;
    setCurrentIndex((currentIndex + direction + totalItems) % totalItems);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      changeSlide(1);
    }, 5000); // 5 seconds

    return () => clearInterval(interval);
  }, [currentIndex]); // Ensure the effect depends on the currentIndex

  const className = {
    container:
      "relative max-w-full md:h-[75vh] h-[40vh] lg:h-screen mt-20 overflow-hidden",
    innerContainer: "flex transition-transform duration-500 bg-black group h-full",
    slide: "min-w-full box-border relative bg-black",
    image: "w-full h-full object-contain",
    button:
      "absolute top-1/2 group-hover:block hidden -translate-y-1/2 left-1/2 backdrop-brightness-50 -translate-x-1/2 px-4 py-2 md:px-5 md:py-3 border rounded-md font-medium font-Poppins text-white transition transform hover:scale-105 shadow-lg bg-black/50 sm:bg-black/70 md:bg-black/50",
    navButton:
      "absolute top-1/2 transform -translate-y-1/2 bg-gray-300 hover:text-white px-6 py-4 md:px-8 md:py-6 rounded-full transition-all duration-200 hover:bg-iconic-pink focus:outline-none",
    prevButton: "left-6 sm:left-7 md:left-8",
    nextButton: "right-6 sm:right-7 md:right-8",
  };

  return (
    <div className={className.container}>
      <div
        className={className.innerContainer}
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {banners.map((banner, index) => (
          <div key={index} className={className.slide}>
            <img
              src={banner.image}
              alt={`Banner ${index + 1}`}
              className={className.image}
            />
            <Link
              className={`${className.button} ${banner.button.className}`}
              to={banner.button.link}
            >
              {banner.button.title}
            </Link>
          </div>
        ))}
      </div>
      <button
        className={`${className.navButton} ${className.prevButton}`}
        onClick={() => changeSlide(-1)}
      >
        &#10094;
      </button>
      <button
        className={`${className.navButton} ${className.nextButton}`}
        onClick={() => changeSlide(1)}
      >
        &#10095;
      </button>
    </div>
  );
};

export default Carousel;

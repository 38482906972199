import img1 from '../attachments/Perfumes/1.png';
import img2 from "../attachments/Perfumes/2.png";
import img3 from "../attachments/Perfumes/3.png";


const Menus = () => {

    const className = {
      container: "w-full h-full lg:h-screen -z-10 grid place-items-center pb-10 md:p-10",
      innerContainer:
        "h-fit grid grid-cols-1 lg:grid-cols-3 place-items-center",
      horGrid: "w-fit h-fit grid grid-cols-3 place-items-center",
      imgLink:
        "hover:shadow-slate-600 hover:shadow-lg transform transition-all duration-300",
      img: "-z-20  md:h-[500px]",
    };

   

    return (
      <div className={className.container}>
        <div className={className.innerContainer}>
          <a
            href="https://shopee.co.id/FTBW-ROSE-GLAM-PARFUM-ELEGANCE-35-ML-i.1212420650.26503435414?xptdk=363bf879-77d6-453e-bce3-23c144ef4261"
            className={className.imgLink}
          >
            <img src={img1} className={className.img} />
          </a>

          <a
            href="https://shopee.co.id/FTBW-JISO-SWEET-ESCAPE-PARFUM-COCOK-UNTUK-PRIA-DAN-WANITA-i.1212420650.28553429287?xptdk=4a4ad3f9-46e5-4a35-b826-85e563b0a61a"
            className={className.imgLink}
          >
            <img src={img2} alt="img3" className={className.img} />
          </a>

          <a
            href="https://shopee.co.id/FTBW-JENNY-LIQUID-FRESH-MUSKY-PARFUM-PRIA-DAN-WANITA-i.1212420650.27053430755?xptdk=635c4ec2-8ed6-40af-8c54-5884d1671b35"
            className={className.imgLink}
          >
            <img src={img3} alt="img4" className={className.img} />
          </a>
        </div>
      </div>
    );
}

export default Menus;
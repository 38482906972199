import React, { useEffect, useState } from "react";

const Carousel = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

   useEffect(() => {
     const timer = setInterval(() => {
       goToNext();
     }, 5000); 
     
     return () => clearInterval(timer); 
   }, [currentIndex]);

  return (
    <div className="w-screen px-10 lg:px-0 lg:max-w-4xl mx-auto mt-10 font-Poppins">
      <div className="relative overflow-hidden rounded-lg">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`min-w-full px-20 py-10 flex flex-col lg:flex-row items-center gap-5 justify-center ${
                index === currentIndex ? "opacity-100" : "opacity-0"
              }`}
              style={{ width: "100%" }}
            >
              <div className="w-[150px] h-[150px] flex-shrink-0">
                <img
                  src={slide.img}
                  alt={slide.title}
                  className="w-full h-full object-cover object-center rounded-full border-4 border-iconic-pink"
                />
              </div>
              <div className="space-y-6 relative text-center lg:text-left px-10">
                <pre className="text-xl text-black md:text-3xl font-bold">
                  {slide.title}
                </pre>
                <div className="space-y-1">
                  <h4 className="text-base lg:text-lg">- {slide.name}</h4>
                  <p className="text-sm lg:text-base text-black/70">
                    {slide.position}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={goToPrevious}
          className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-300 hover:text-white px-5 py-3 rounded-full transition-all duration-200 hover:bg-iconic-pink focus:outline-none"
        >
          &#10094;
        </button>
        <button
          onClick={goToNext}
          className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-300 hover:text-white px-5 py-3 rounded-full transition-all duration-200 hover:bg-iconic-pink focus:outline-none"
        >
          &#10095;
        </button>
      </div>
    </div>
  );
};

export default Carousel;

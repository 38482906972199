import React, { useState } from "react";
import { TiThMenu } from "react-icons/ti";
import logo from "../attachments/logo.png";
import db from "../DB/Database.js";
import Sidebar from "./Sidebar"; // Import the Sidebar component
import second from '../Pages/ShopStores.js';
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const className = {
    container:
      "w-full h-auto p-6 flex items-center z-20 justify-between lg:grid grid-cols-3 place-items-center fixed top-0 inset-x-0 bg-black",
    menu: "hidden lg:flex items-center justify-center gap-12",
    link: "no-underline font-Poppins text-white hover:text-iconic-pink",
    button:
      "p-3 w-fit h-fit grid place-items-center rounded-full bg-iconic-pink cursor-pointer",
    menuButton: "w-auto h-auto block lg:hidden p-3 rounded-lg text-white",
    logo: "w-fit h-fit px-10 py-2 bg-white rounded-tr-lg rounded-bl-lg",
  };

  const Data = db.navbar;

  return (
    <>
      <div className={className.container}> 
        <ul className={className.menu}>
          {Data.menu1.map((data, index) => {
            if (data.link === "") {
              return (
                <li key={index} className="flex items-center gap-2">
                  <img src={data.title} alt="icon" className="w-6 h-6" />
                </li>
              );
            } else {
              return (
                <li key={index}>
                  <Link to={data.link} className={className.link}>
                    {data.title}
                  </Link>
                </li>
              );
            }
          })}
        </ul>

        <Link href="/" className={className.logo}>
          <img src={logo} alt="Logo" className="h-10" />
        </Link>

        <ul className={className.menu}>
          {Data.menu2.map((data, index) => {
            if (data.link !== "/Contact") {
              return (
                <li key={index}>
                  <Link
                    to={data.link}
                    className={className.link}
                  >
                    {data.title}
                  </Link>
                </li>
              );
            } else {
              return (
                <li key={index} className="flex items-center gap-2">
                  <Link to={data.link}>
                    <img src={data.title} alt="contact" className="w-6 h-6" />
                  </Link>
                </li>
              );
            }
          })}
        </ul>

        <button onClick={toggleSidebar} className={className.menuButton}>
          <TiThMenu />
        </button>
      </div>

      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        Data={Data}
      />
    </>
  );
};

export default Navbar;

import Navbar from "../Components/Navbar";
import Carousel from "../Pages/Home";
import Menus from "../Pages/Menus";
import ShopCatalogue from "../Pages/ShopCat";
import Showcase from "../Pages/Showcase";
import Caroucase from "../Pages/CarouCase";
import Footer from "../Components/Footer";
import Banner from "../Pages/Banner";

const Homepage = () => {

    return (
      <div className="w-screen overflow-x-hidden h-full">
        <Carousel />
        <Menus />
        <ShopCatalogue />
        <Banner />
        <Showcase />

        <Caroucase />
      </div>
    );
}

export default Homepage;
import { useState } from "react";
import Database from "../DB/Database";
import { FaPaperPlane } from "react-icons/fa";
import "../App.css";
const Footer = () => {
    const [email, setEmail] = useState('');
    const [message , setMessage] = useState('');

    const className = {
      container: "w-full h-fit p-5 space-y-2 bg-[#282525] text-white ",
      box: "w-full h-fit p-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 place-items-center",
      innerBox: "w-fit h-full p-5 space-y-5 text-white placeholder-slate-400",
      verticalLists: "space-y-3 list-none",
      title: "text-xl xl:text-2xl font-semibold",
      list: "flex items-center justify-start gap-2",
      link: "no-underline text-white/70 hover:underline",
      horizontalList: "flex w-full justify-center items-center gap-4",
      socialLinks: "block w-full h-fit border hover:bg-white hover:text-black border-white py-3 px-7 rounded-sm text-white"
    };

    const handleSubmit = (e) => {
         e.preventDefault();
        const mailtoLink = `mailto:ftbwcosmetic@gmail.com?subject=Newsletter Subscription&body=Email: ${email}%0D%0AMessage: ${encodeURIComponent(message)}`;
        window.location.href = mailtoLink;

        setEmail('');
        setMessage('');
    }

    const Data = Database.footer;

    return (
      <div className={className.container}>
        <div className={className.box}>
          <div className={className.innerBox}>
            <h1 className={className.title}>{Data.contactTitle}</h1>
            <ul className={className.verticalLists}>
              {Data.contacts.map((data, idx) => {
                return (
                  <li key={idx} className={className.list}>
                    {data.icon}{" "}
                    <a className={className.link} href={data.link}>
                      {data.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className={className.innerBox}>
            <h1 className={className.title}>{Data.socialTitle}</h1>
            <ul className={className.horizontalList}>
              {Data.socials.map((data, idx) => {
                return (
                  <li key={idx}>
                    <a className={className.socialLinks} href={data.link}>
                      {data.icons}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className={`${className.innerBox} newsletter-container`}>
            <h1 className={className.title}>{Data.news.title}</h1>

            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <textarea
                placeholder="Your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button type="submit">
                Subscribe <FaPaperPlane />
              </button>
            </form>
          </div>
        </div>

        <div className={className.copybox}></div>
      </div>
    );
}

export default Footer;
import { SiShopee, SiTiktok } from "react-icons/si";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

const ShopStores = () => {
    const className = {
        container : "pt-20 w-full h-screen space-y-10",
        grid: "px-10 w-screen h-screen grid grid-cols-1 md:grid-cols-2 gap-20 md:gap-5 place-content-center",
        boxed: "text-4xl w-fit h-fit block p-10 rounded-full border border-black bg-transparent hover:bg-iconic-black hover:border-white hover:text-iconic-pink",
        box: "w-full h-full grid gap-5 place-items-center text-lg font-bold font-Poppins"
    }
    
    
    return (
      <div className={className.container}>
        <div className={className.grid}>
          <div className={className.box}>
            <a
              className={className.boxed}
              href="https://shopee.co.id/ftbwcosmetic"
            >
              <SiShopee />
            </a>
            See more of Our Products!
          </div>
          <div className={className.box}>
            <a
              className={className.boxed}
              href="https://www.tiktok.com/@ftbw_cosmetic?_t=8l4XpURPoS9&_r=1"
            >
              <SiTiktok />
            </a>
            <p>Visit our Tiktok Account -&gt;</p>
          </div>
        </div>
      </div>
    );
}


export default ShopStores;